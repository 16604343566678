import React, { useState, useEffect } from "react";
import { ValidateResponse} from "../../../helpers/global";
import { getProductDetails, actionProductEdit,  getArticleCustomfields } from "../../../helpers/articles";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../../../../components/Form/Fields";
import { FormInputSubmit } from "../../../../components/Form/Input/Submit";
import { Message } from "../../../../components/Message";
import { ContentWrapper } from "../../../../components/Content/Wrapper";

export function AppArticlesProductActionsEdit({ productId, parents }) {

    const navigate = useNavigate();
    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({});
    const [customFields, setCustomFields] = useState();

    useEffect(() => {
        if(state !== null && state !== undefined){
            getArticleCustomfields(state.productTemplateId).then((res) => {

                const response = ValidateResponse(res.data);
                if (response.status === 'success') {

                    setCustomFields(response.response);
                }
                else {
                    setError(response.message);
                }
            })
        }
    }, [state])

    useEffect(() => {

        getProductDetails(productId).then((res) => {
            const response = ValidateResponse(res.data, 'details');
            if (response.status === 'success') {
                setState(response.details);

                let values = {
                    productId: productId,
                    productBrand: response.details.productBrand,
                    constructionYear: response.details.constructionDate,
                    productType: response.details.productType,
                    dimensions: response.details.dimensions,
                    frameNumber: response.details.frameNumber,
                    serialNumber: response.details.serialNumber,
                    remarks: response.details.remarks,
                }

                Object.keys(response.details.customFields).forEach((statekey) => {
                        let id = response.details.customFields[statekey].id;
                        values = {...values, [id]:response.details.customFields[statekey].valueRaw};
                });

                setValues(values);
                setIsLoaded(true);
            }
            else {
                setError(response.message);
            }
        })
    }, [productId])

    const handleInput = (name, value, identifier) => {

        let newValues = values;
        if (newValues.customFields === undefined) {
            newValues.customFields = {};
        }
        // add customfield
        if (customFields !== undefined && customFields[name] !== undefined) {
            newValues.customFields[name] = value;
        }
        else {
            newValues[name] = value;
        }

        if (identifier !== undefined && identifier !== null) {
            if (newValues[identifier] === undefined) {
                newValues[identifier] = {}
            }
            newValues[identifier][name] = value;
        }
        else {
            newValues[name] = value;
        }

        setValues(newValues);
        return;
    }

    const sendValues = () => {

        let requiredValues = [];

        // Validate required fields
        let validation = {};

        requiredValues.forEach((requiredField) => {
            if (values[requiredField] === undefined) {
                setError('Veld niet gevonden: ' + requiredField);
                validation[requiredField] = 'Verplicht veld';
            }
            else if (values[requiredField] === null || values[requiredField].length === 0) {
                validation[requiredField] = 'Verplicht veld';
            }
        });

        if (Object.keys(validation).length !== 0) {
            setFormErrors(validation);
            return;
        }

        let postData = {productId: productId, customFields: values.customFields};

        Object.keys(values).forEach((postkey) => {
            Object.keys(fields).forEach((fieldkey) => {
                if(fieldkey === postkey){
                    postData = {...postData, [postkey]: values[postkey]}
                }
            });
        });

        actionProductEdit(postData).then((res) => {

            const response = ValidateResponse(res.data, 'status');

            if (response.status === 'success') {
                navigate("/" + parents.replace("actions", "details/") + productId, {
                    replace: true,
                    state: { message: response.message, type:response.status}
                });
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (state === undefined || state === null || Object.keys(state).length === 0) {
        return (<Message type="noData" />);
    }

    const fields = {
        'productBrand': { type: 'text', label: "Merk", value:values.productBrand},
        'productType': { type: 'text', label: "Type" },
        'dimensions': { type: 'text', label: "Afmetingen" },
        'constructionYear': {
            type: "date",
            label: "Bouwjaar",
            options: { min: "-125", max: null },
        },
        'remarks': { type: 'textarea', label: "Opmerkingen"},
        'serialNumber': { type: 'text', label: "Serienummer" },
        'frameNumber': { type: 'text', label: "Framenummer" }
    }

        // Add custom fields
        if (customFields !== undefined) {
            Object.keys(customFields).forEach((key) => {
                fields[key] = {
                    type: customFields[key].type,
                    label: customFields[key].properties.label,
                    options: customFields[key].properties.options ?? null,
                }
            });
    
        }

    const content = () => {
        return <>

            <h3 className="box-header">Artikelgegevens wijzigen</h3>
            <FormFields
                formFields={fields}
                setValues={handleInput}
                setErrors={setFormErrors}
                formValues={values}
                formErrors={formErrors}
            />
            <FormInputSubmit
                handleSubmit={sendValues}
                cancelUri={"/" + parents + "/" + productId}
            />
        </>
    }

    return (
        <ContentWrapper
            type="outline-box"
            content={content()}
            className="outline-box--form"
        />
    );
}