import React, { useState } from "react";
import { getArticleCustomfields, actionProductAdd } from "../helpers/articles";
import { ValidateResponse } from "../helpers/global";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../../components/Form/Fields";
import { FormInputSubmit } from "../../components/Form/Input/Submit";
import { Message } from "../../components/Message";
import { ContentWrapper } from "../../components/Content/Wrapper";

export function AppArticlesAdd() {

    const navigate = useNavigate();
    const [state, setState] = useState('step1');
    const [error, setError] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({});
    const [customFields, setCustomFields] = useState();

    const getCustomFields = (articleId) => {
        getArticleCustomfields(articleId).then((res) => {

            const response = ValidateResponse(res.data);
            if (response.status === 'success') {
                setCustomFields(response.response);
                setState('step2');
            }
            else {
                setError(response.message);
            }
        })
    }

    const handleInput = (name, value) => {

        let newValues = values;
        if (newValues.customFields === undefined) {
            newValues.customFields = {};
        }
        // add customfield
        if (customFields !== undefined && customFields[name] !== undefined) {
            newValues.customFields[name] = value;
        }
        else {
            newValues[name] = value;
        }
        
        setValues(newValues);

        if (name === 'articleCategory'
            && newValues.articleCategory.article !== undefined
            && newValues.articleCategory.article !== null
            && newValues.articleCategory.articleId > 0) {

            getCustomFields(newValues.articleCategory.articleId);
        }
        return;
    }

    const sendValues = () => {

        let postData = values;

        postData.articleId = values.articleCategory.articleId;

        let requiredValues = ['articleId', 'locationId', 'serialNumber'];

        // Validate required fields
        let validation = {};

        requiredValues.forEach((requiredField) => {
            if (postData[requiredField] === undefined) {
                setError('Veld niet gevonden: ' + requiredField);
                validation[requiredField] = 'Verplicht veld';
            }
            else if (postData[requiredField] === null || postData[requiredField].length === 0) {
                validation[requiredField] = 'Verplicht veld';
            }
        });

        if (Object.keys(validation).length !== 0) {
            setFormErrors(validation);
            return;
        }

        actionProductAdd(postData).then((res) => {

            const response = ValidateResponse(res.data, 'product');
            if (response.status === 'success') {

                navigate("/articles/location/product/actions/scan/" + response.product.productId, {
                    replace: true,
                    state: { message: response.product.message, type: response.status}
                });

            }
            else if (response.response !== null && values[response.response] !== undefined) {
                let formErrors = {}
                formErrors[response.response] = response.message;
                setFormErrors(formErrors);
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }

    // if (error !== undefined) {
    //     return (<Message type="error" message={error} />);
    // }

    let fields = {
        'step1': {
            articleCategory: { type: 'categories', label: "Selecteer artikel", options: { output: "depot", category:"categoryId", article:"articleId"} }
        },
        'step2': {
            'location': { type: 'location' },
            'clientName': { type: 'text', label: "Naam cliënt" },
            'serialNumber': {
                type: 'text', label: "Serienummer", rules: ["required"],
                info: "Serienummer mag bestaan uit hoofdletters, cijfers,koppelteken (-) of punt (.). Andere leestekens, spaties of speciale tekens worden genegeerd."
            },
            'frameNumber': { type: 'text', label: "Framenummer" },
            'constructionYear': { type: 'text', label: "Bouwjaar" },
            'productBrand': { type: 'text', label: "Merk" },
            'productType': { type: 'text', label: "Type" },
            'dimensions': { type: 'text', label: "Afmetingen" },
            'remarks': { type: 'textarea', label: "Opmerkingen" },
        }
    }

    // Add custom fields
    if (customFields !== undefined) {
        Object.keys(customFields).forEach((key) => {
            fields.step2[key] = {
                type: customFields[key].type,
                label: customFields[key].properties.label,
                options: customFields[key].properties.options ?? null,
            }
        });

    }

    let title = "Hulpmiddel registreren";
    if (values.articleCategory !== undefined
        && values.articleCategory.article !== undefined
        && values.articleCategory.article !== null) {
        title += " | " + values.articleCategory.article.name;
    }

    const content = () => {
        return <>
            <h3 className="box-header">{title}</h3>
            <FormFields
                formFields={fields[state]}
                setValues={handleInput}
                setErrors={setFormErrors}
                formValues={values}
                formErrors={formErrors}
                identifier="product-add"
            />
            {state === 'step2' && customFields !== undefined &&
                <FormInputSubmit
                    handleSubmit={sendValues}
                    cancelUri={"/articles/actions"}
                    submitLabel={state === 'step1' ? "Volgende stap" : "Verzenden"}
                />
            }
        </>
    }

    return (
        <>
            <Message type="error" message={error} />
            <ContentWrapper
                type="outline-box"
                content={content()}
                className="outline-box--form"
            />
        </>
    );
}