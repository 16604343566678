import React, { useState, useEffect} from "react";
import { getProductDetails } from "../../helpers/articles";
import { ValidateResponse } from "../../helpers/global";
import { Message } from "../../../components/Message";
import { Details } from "../../../components/Details";

export function AppArticlesProductDetails({ productId}) {

    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        getProductDetails(productId).then((res) => {

            const response = ValidateResponse(res.data, 'details');

            if (response.status === 'error') {
                setError(response.message, response.status);
            }
            else {
                setState(response.details);
                setIsLoaded(true);
                localStorage.setItem("product-" + productId, JSON.stringify(response.details))
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        });
        
    }, [productId]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (state === undefined || state === null || Object.keys(state).length === 0) {
        return (<Message type="noData" />);
    }

    let allColums = [
        // west
        { key: "articleName", label: "Artikelnaam", type: "text", gcId:"west"},
        { key: "serialNumber", label: "Serienummer", type: "text", gcId:"west"},
        { key: "frameNumber", label: "Framenummer", type: "text", gcId:"west"},
        { key: "barcodeDisplay", label: "Qr code", type: "text", gcId:"west"},
        { key: "statusDisplay", label: "Productstatus", type: "text", gcId:"west"},
        { key: "depotStatus", label: "Status depot", type: "text", gcId:"west"},
        { key: "startDate", label: "Startdatum depot", type: "date", gcId:"west"},
        { key: "owner", label: "Locatie", type: "text", gcId:"west"},
        { key: "departmentRoom", label: "Afdeling/kamernr.", type: "text", gcId:"west"},

        // east
        { key: "productBrand", label: "Merk", type: "text", gcId:"east"},
        { key: "productType", label: "Type", type: "text", gcId:"east"},
        { key: "dimensions", label: "Afmetingen", type: "text", gcId:"east"},
        { key: "constructionYear", label: "Bouwjaar", type: "text", gcId:"east"},
    ];

    // Set/remove status-specific fields
    let columns = [];

    allColums.forEach((row) => {
        if ((state.startDate  === undefined || state.startDate === null)
            //&& row.key !== 'productStatus'
            && row.key !== 'depotStatus'
            && row.key !== 'startDate') {
                columns.push(row);
        }
        else if ((state.startDate  !== undefined && state.startDate !== null)
            && row.key !== 'owner'
            && row.key !== 'departmentRoom') {
                columns.push(row);
        }
    });

    // Divide in to left/right
    if (state.customFields !== undefined && state.customFields.length > 0) {
        state.customFields.forEach((item) => {
            columns.push({ label: item.label, value:item.value, type: "text", gcId:"east"});
        });
    }

    if (state.currentValue !== undefined && state.currentValue !== null) {
        columns.push({ key: "currentValue", label: "Dagwaarde", type: "text", gcId:"east"});
    }

    // and last but not least the comments
    columns.push({ key: "remarks", label: "Opmerkingen", type: "text", gcId:"east"});

    let message = state.status === 'REMOVED' ? "Huplmiddel is afgevoerd" : null;
    let messageTitle;

    if(state.enableReservation === "1") {
        messageTitle = "Reservering actief"
        message =  state.reservationReason;
    }

    return (<>
        <Message type="warning" message={message} title={messageTitle} />

        <Details
            columns={columns}
            data={state}
            identifier="details"
        />
    </>);
}