import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage, setCurrentPage } from "../helpers/global";
import { fetchProducts } from "../helpers/articles";
import { List } from "../../components/List";
import { ListPagination } from "../../components/List/Pagination";
import { Message } from "../../components/Message";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppArticlesOverview({ current, setFilterFields, filterValues, identifier }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    const [products, setProducts] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadFilters, setLoadFilters] = useState(true);
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters" + identifier)));
    const [filterString, setFilterString] = useState("{}");

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let allColums = [
        { key: "title", type: "link", path: "product/details/:productId", hideView: "large" },
        { key: "enableReservation", label: "", type:"icon", options:{1:"warning"}},
        { key: "articleName", label: "Artikelnaam", type: "text", hideView: 'small' },
        { key: "serialNumber", label: "Serienr.", type: "text", hideView: 'small' },
        { key: "productStatus", label: "Productstatus", type: "text" },
        { key: "startDate", label: "Startdatum depot", type: "date" },
        { key: "owner", label: "Locatie", type: "text" },
        { key: "departmentRoom", label: "Afdeling/kamernr.", type: "text" },
        { key: "constructionYear", label: "Bouwjaar", type: "text" },
        { key: "productBrand", label: "Merk", type: "text" },
        { key: "productType", label: "Type", type: "text" },
        { key: "quality", label: "Kwaliteit", type: "text" },
        { key: "productId", type: "link", path: "product/details/:productId", id: "productId", hideView: "small" },
    ];

    // Filter relevant columns for depot status in-or out
    let listColumns = [];
    allColums.forEach((row) => {
        if (current === 'location'
            && row.key !== 'productStatus'
            && row.key !== 'depotStatus'
            && row.key !== 'startDate'
            && row.key !== 'enableReservation') {
            listColumns.push(row);
        }
        else if (current === 'depot'
            && row.key !== 'owner'
            && row.key !== 'departmentRoom') {
            listColumns.push(row);
        }
    });

    const defineFilterFields = () => {

        const optionsQuality = [
            { label: "Goed", value: "GOOD" },
            { label: "Matig", value: "AVERAGE" },
            { label: "Slecht", value: "BAD" }
        ]

        // The filters are set here and send to parent onloaded
        let filterFields = {
            articleCategory: { type: 'categories', label: "Artikelcategorie", options: { output: "depot", category: "categoryId", article: "articleId" } },
            location: { type: 'location' },
            clientName: { type: 'text', label: "Naam cliënt" },
            serialNumber: { type: 'text', label: "Serienr." },
            frameNumber: { type: 'text', label: "Framenr." },
            constructionYearFrom: { type: 'text', label: "Bouwjaar van" },
            constructionYearTo: { type: 'text', label: "Bouwjaar tot" },
            quality: { type: 'select', label: "Kwaliteit", options: optionsQuality },
        }

        if (current === 'depot') {
            delete filterFields['location'];
        }

        setFilterFields(filterFields);
    }

    useEffect(() => {

        async function fetchData() {

            const callData = {
                filters: {
                    tabType: current,
                    limitOffset: pageSize * pageNr - (pageSize - 1),
                    limitRows: pageSize,
                    ...filters
                }
            }

            await fetchProducts(callData).then((res) => {

                const response = ValidateResponse(res.data, 'products');
                if (response.status === 'success') {

                    if (response.totalCount !== undefined) {
                        setTotalCount(response.totalCount);
                    }
   
                    // response.products.forEach((product, index) => {
                    //     if(product.enableReservation === '1'){
                    //         product.enableReservation = 'info';
                    //    }
                    // });

                    setProducts(response.products);
                    setIsLoaded(true);

                    if (loadFilters !== current) {
                        defineFilterFields();
                        setLoadFilters(current);
                    }
                }
                else {
                    setError(response.message);
                }
            }).catch((error) => {
                const response = ValidateResponse(error);
                setError(response.message);
            });
        }

        fetchData();
    }, [current, pageNr, filters, filterString, pageSize]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (products === undefined || products === null || Object.keys(products).length === 0) {
        return (<Message type="noData" />);
    }

    return (
        <>
            <List
                columns={listColumns}
                data={products}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                setCurrentPage={handlePagination}
                totalCount={totalCount}
                pageSize={pageSize}
            />
            <div style={{ height: '30px' }}></div>
        </>
    );
}