import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getProductBarcode, getProductStatus } from "../../helpers/articles";
import { validateRoute } from "../../helpers/auth";
import { Message } from "../../../components/Message";
import { ContentWrapper } from "../../../components/Content/Wrapper";

export function AppArticlesProductActions({ parents, productId }) {

    let basePath = "/" + parents + "/";
    let tabItem = parents.indexOf('articles/depot') === -1 ? 'location' : 'depot';
    const [barcode, setBarCode] = useState(false);
    const [productStatus, setProductStatus] = useState();

    useEffect(() => {
        (async () => {
            try {
                const fetchedStatus = await getProductStatus(productId);
                setProductStatus(fetchedStatus);
            } catch (err) {
                console.log('Error catching product status');
            }
        })();
    }, []);


    if (barcode === false) {
        getProductBarcode(productId, setBarCode);
    }

    const content = () => {
        return (
            <>
                {barcode !== false && validateRoute(basePath + "actions/scan/:productId") === true &&
                    <div className="tiles__column">
                        <Link to={basePath + "actions/scan/" + productId} className="tile tile__scannen active">
                            <span className="icon"></span>
                            <span className="tile__title">
                                Qr code<br />koppelen
                            </span>
                        </Link>
                    </div>
                }

                {tabItem === 'depot' && validateRoute(basePath + "actions/depotout/:productId") === true &&

                    <div className="tiles__column">
                        <Link to={basePath + "actions/depotout/" + productId} className="tile tile__registreren">
                            <span className="icon"></span>
                            <span className="tile__title">
                                Leveren<br />uit depot
                            </span>
                        </Link>
                    </div>
                }
                {tabItem === 'depot' && validateRoute(basePath + "actions/reservation/:productId") === true &&
                <div className="tiles__column">
                    <Link to={basePath + "actions/reservation/" + productId} className="tile tile__registreren">
                        <span className="icon"></span>
                        <span className="tile__title">
                            Reservering
                        </span>
                    </Link>
                </div>
                }
                {tabItem === 'location' &&
                    <>
                        {validateRoute(basePath + "actions/relocate/:productId") === true &&
                            <div className="tiles__column">
                                <Link to={basePath + "actions/relocate/" + productId} className="tile tile__registreren">
                                    <span className="icon"></span>
                                    <span className="tile__title">
                                        Locatie<br />wijzigen
                                    </span>
                                </Link>
                            </div>
                        }
                        {validateRoute(basePath + "actions/repair/:productId") === true &&
                            <div className="tiles__column">
                                <Link to={basePath + "actions/repair/" + productId} className="tile tile__reparatie">
                                    <span className="icon"></span>
                                    <span className="tile__title">
                                        Reparatieverzoek<br />indienen
                                    </span>
                                </Link>
                            </div>
                        }
                        {validateRoute(basePath + "actions/clientrepair/:productId") === true &&
                            <div className="tiles__column">
                                <Link to={basePath + "actions/clientrepair/" + productId} className="tile tile__reparatie">
                                    <span className="icon"></span>
                                    <span className="tile__title">
                                        Reparatie<br />Registreren
                                    </span>
                                </Link>
                            </div>
                        }
                        {validateRoute(basePath + "actions/depot/:productId") === true &&
                            <div className="tiles__column">
                                <Link to={basePath + "actions/depot/" + productId} className="tile tile__depotopslag">
                                    <span className="icon"></span>
                                    <span className="tile__title">
                                        Aanvraag<br />depotopslag
                                    </span>
                                </Link>
                            </div>
                        }
                        {validateRoute(basePath + "actions/client/:productId") === true &&

                            <div className="tiles__column">
                                <Link to={basePath + "actions/client/" + productId} className="tile tile__registreren">
                                    <span className="icon"></span>
                                    <span className="tile__title">
                                        Naam<br />cliënt
                                    </span>
                                </Link>
                            </div>
                        }
                    </>
                }
                {validateRoute(basePath + "actions/edit/:productId") === true &&
                    <div className="tiles__column">
                        <Link to={basePath + "actions/edit/" + productId} className="tile tile__registreren">
                            <span className="icon"></span>
                            <span className="tile__title">
                                Gegevens<br />hulpmiddel<br />wijzigen
                            </span>
                        </Link>
                    </div>
                }
            </>
        )
    }

    const reservationMessage = () => {
        let message = "";
        let product = JSON.parse(localStorage.getItem("product-" + productId));
        if(product !== undefined && product.reservationReason !== undefined) {
            message = product.reservationReason;
        }
        return message;
    }


    if (productStatus === 'REMOVED') {
        return <Message type="warning" message="Hulpmiddel is afgevoerd" />
    }
    else if (productStatus === 'RESERVED') {
        return (
            <>
                <Message type="warning" title="Reservering actief" message={reservationMessage()} />
                <ContentWrapper type="tiles-secondary" content={content()} />
            </>
        )
    }
    else {
        return <ContentWrapper type="tiles-secondary" content={content()} />
    }

}